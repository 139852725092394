/*
 * @Descripttion: 
 * @version: 
 * @Author: renqiang_hu
 * @Date: 2021-12-03 14:31:59
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2021-12-22 09:13:55
 * @FilePath: \kechuang_web\src\api\wel.js
 */
import request from '@/router/axios';

// 年度标题语

export const newest = (code) => {
  return request({
    url: '/api/slogan/newest',
    method: 'get',
    params: code
  })
}

// 技术研究开发费
export const techDevcost = (code) => {
    return request({
      url: '/api/dataview/home/techDevcost',
      method: 'get',
      params: code
    })
}

// 高新产业投资
export const highInvest = (code) => {
    return request({
      url: '/api/dataview/home/highInvest',
      method: 'get',
      params: code
    })
}


// 高新技术产业增加值
export const highIncrease = (code) => {
    return request({
      url: '/api/dataview/home/highIncrease',
      method: 'get',
      params: code
    })
}


// 技术合同交易额
export const techDeal = (code) => {
    return request({
      url: '/api/dataview/home/techDeal',
      method: 'get',
      params: code
    })
}


// 高新技术企业
export const hightechEnterprise = (code) => {
    return request({
      url: '/api/dataview/home/hightechEnterprise',
      method: 'get',
      params: code
    })
}


// 省级科技型中小企业
export const pmandstechEnterprise = (code) => {
    return request({
      url: '/api/dataview/home/pmandstechEnterprise',
      method: 'get',
      params: code
    })
}


// 省级研发机构
export const presearchEnterprise = (code) => {
    return request({
      url: '/api/dataview/home/presearchEnterprise',
      method: 'get',
      params: code
    })
}


// 绿谷精英.创新引领计划
export const lgjyInnovateEnterprise = (code) => {
    return request({
      url: '/api/dataview/home/lgjyInnovateEnterprise',
      method: 'get',
      params: code
    })
}


// 指标雷达图
export const indexRadar = (code) => {
    return request({
      url: '/api/dataview/home/indexRadar',
      method: 'get',
      params: code
    })
}



// 产业情况
export const indexCity = (code) => {
    return request({
      url: '/api/dataview/industry/indexCity',
      method: 'get',
      params: code
    })
}


// 获取缙云县专利申请量与授权量（件）
export const patentTown = (code) => {
  return request({
    url: '/api/dataview/innovate/result/patentTown',
    method: 'get',
    params: code
  })
}


// 获取丽水市专利申请量与授权量（件）
export const patentCity = (code) => {
  return request({
    url: '/api/dataview/innovate/result/patentCity',
    method: 'get',
    params: code
  })
}


// 各县（市区）有效发明专利拥有量（件）
export const inventPatentCity = (code) => {
  return request({
    url: '/api/dataview/innovate/result/inventPatentCity',
    method: 'get',
    params: code
  })
}

// 每万人发明专利拥有量（件/万人）
export const wanPatentCity = (code) => {
  return request({
    url: '/api/dataview/innovate/result/wanPatentCity',
    method: 'get',
    params: code
  })
}

// 标准化建设
export const standard = (code) => {
  return request({
    url: '/api/dataview/innovate/result/standard',
    method: 'get',
    params: code
  })
}

// 科技奖项
export const scienceAward = (code) => {

  return request({
    url: '/api/dataview/innovate/result/scienceAward',
    method: 'get',
    params: code
  })
}


// 获取城市各项指标最新年度日期列表
export const getNewestYearList = (code) => {

  return request({
    url: '/api/dataview/industry/getNewestYearList',
    method: 'get',
    params: code
  })
}