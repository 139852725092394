import NProgress from 'nprogress'; // progress bar
import router from '@/router/index';
import { Message } from 'element-ui';
import 'nprogress/nprogress.css';
import { Loading } from 'element-ui';
import axios from 'axios';
import website from '@/config/website';
axios.defaults.timeout = 300000;
// axios.defaults.uploadbaseURL = 'http://192.168.110.82:7002/'; // 单独设置文件上传接口
// axios.defaults.baseURL = 'http://192.168.1.88:9999/'
// 返回其他状态吗
axios.defaults.validateStatus = function(status) {
  return status >= 200 && status <= 500; // 默认的
};
let loadingInstance = null;
// 跨域请求，允许保存cookie
axios.defaults.withCredentials = true;
// NProgress Configuration
NProgress.configure({
  showSpinner: false
});

// HTTPrequest拦截
axios.interceptors.request.use(
  config => {
    // console.log(config)
    NProgress.start(); // start progress bar

    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

// HTTPresponse拦截
axios.interceptors.response.use(
  res => {
    NProgress.done();
    const status = res.status || 200;
    const message = res.data.msg || res.data.error_description || '未知错误';
    // console.log(status, '---res-');
    if (res.data.code === 401) {
      // debugger
      router.push({
        path: '/'
      });
      return;
    }
    if (res.data.error_code) {
      Message({
        message: message,
        type: 'error'
      });
      return Promise.reject(new Error(message));
    }
    if (status !== 200 || res.data.code === 1) {
      Message({
        message: message,
        type: 'error'
      });
      return Promise.reject(new Error(message));
    }

    return res;
  },
  error => {
    console.log(error);
    NProgress.done();
    return Promise.reject(new Error(error));
  }
);

export default axios;
