<template>
  <section class="center_container">
    <!-- <div class="top_title">
      <span>年度指标情况</span>
    </div> -->
    <Breadcrumbnav :value2=value2></Breadcrumbnav>
    <!-- <div class="tips">当年4月1日之前查询上年度，之后查询本年度</div> -->
          <el-date-picker
          v-model="value2"
          type="year"
          size="samll" @change="chagedate"
          value-format="yyyy"
          placeholder="选择年">
        </el-date-picker>
        
         
    <div class="chart_box wrraper" style="height: 440px" id="radarChart">
      <p class="zbqingk"><span></span>年度目标</p>
    </div>
    <div class="chart chart_box">
      <p class="chart_title">规上研发经费</p>
      <div id='jschart' class="chart_content"></div>
    </div>

     <div class="chart chart_box">
      <p class="chart_title">  高新产业投资增长率</p>
      <div id='gxchart' class="chart_content"></div>
    </div>

    <div class="chart chart_box">
      <p class="chart_title">  高新技术产业增加值</p>
      <div id='gjchart' class="chart_content"></div>
    </div>
    <div class="chart chart_box">
        <p class="chart_title">  技术合同交易额</p>
          <div id='jhchart' class="chart_content"></div>
    </div>

    <div class="chart chart_box">
      <p class="chart_title"> 省级科技型中小企业</p>
      <div id='sjchart' class="chart_content"></div>
    </div>
      <div class="chart chart_box" >
      <p class="chart_title">高新技术企业</p>
      <div id='comchart' class="chart_content"></div>
    </div>
      <div class="chart chart_box" >
      <p class="chart_title">省级研发机构</p>
      <div id='jgchart' class="chart_content"></div>
    </div>
    <div class="chart chart_box">
      <p class="chart_title">  绿谷精英·创新引领行动计划</p>
      <div id='lgchart' class="chart_content"></div>
    </div>
  </section>
</template>
<script>
import moment from 'moment'
import {
  techDevcost,
  highInvest,
  highIncrease,
  techDeal,
  hightechEnterprise,
  pmandstechEnterprise,
  presearchEnterprise,
  lgjyInnovateEnterprise,
  indexRadar,
} from "@/api/wel";
export default {
  components: {
    Breadcrumbnav: () => import("./Breadcrumbnav.vue"),
  },
  data() {
    return {
      value2: '2021',
      xdata:['1月','2月','3月','4月','5月','6月','7月','8月','9月','10月','11月','12月'],
      namelist:[{name:'技术（研究）开发费',value:'tech_devcost'},
                {name:'高新产业投资建设',value:'high_invest'},
                {name:'高新技术产业增加值',value:'high_increase'},
                {name:'技术合同交易额',value:'tech_deal'},
                {name:'高新技术企业',value:'hightech_enterprise'},
                {name:'省级科技型中小企业',value:'pmandstech_enterprise'},
                {name:'省级研发机构 ',value:'presearch_enterprise'},
                {name:'绿谷精英.创新引领计划',value:'lgjy_innovate_enterprise'},
      ],
      radarChartData: {
        xAxis: [
          "加快高新技术产业投资\n$13$亿元",
          `加强企业研\n发机构建设\n$10$家`,
          `提高全县研发\n经费投入强度\n$9.1$亿元`,
          `实施科技企业双倍增计划\n—省级科技型中小企业$50$家`,
          `实施科技企业\n双倍增计划—\n高新技术企业\n$30$家`,
          `积极引进培\n育高端科技\n人才$17$人`,
        ],
        yAxis: [3.29, 15, 4.96, 38, 56, 31],
        max: [7, 30, 10, 50, 100, 50],
      },
      barChartData1: {
        xAxis: [
          "2月",
          "3月",
          "4月",
          "5月",
          "6月",
          "7月",
          "8月",
          "9月",
          "10月",
          "11月",
          "12月",
        ],
        yAxis: [0.62, 1.67, 2],
        color: ["#FFF991"],
      },
      barChartData2: {
        xAxis: [
          "2月",
          "3月",
          "4月",
          "5月",
          "6月",
          "7月",
          "8月",
          "9月",
          "10月",
          "11月",
          "12月",
        ],
        yAxis: [0.94, 1.2, 2.36],
        color: ["#91FFA3"],
      },
      barChartData3: {
        xAxis: [
          "2月",
          "3月",
          "4月",
          "5月",
          "6月",
          "7月",
          "8月",
          "9月",
          "10月",
          "11月",
          "12月",
        ],
        yAxis: [8, 1, 12],
        color: ["#91B5FF"],
      },
      barChartData4: {
        xAxis: [
          "2月",
          "3月",
          "4月",
          "5月",
          "6月",
          "7月",
          "8月",
          "9月",
          "10月",
          "11月",
          "12月",
        ],
        yAxis: [4, 5, 6],
        color: ["#AD91FF"],
      },
      lineChartData: {
        xAxis: [
          "2月",
          "3月",
          "4月",
          "5月",
          "6月",
          "7月",
          "8月",
          "9月",
          "10月",
          "11月",
          "12月",
        ],
        yAxis1: [14, 17, 25],
        yAxis2: [9, 13, 16],
      },
    };
  },
  mounted() {
    this.init()

  },
  methods: {
    init () {
      this.getindexRadar();
      this.getjschart();
      this.getgxchart()
      this.getgjchart()
      this.getsjchart()
      this.getlgchart()
      
      this.getcomchart()
      this.getjgchart()
      this.getjhchart()
    },
    chagedate(val){
      this.init()
    },
    getstyle(name, res) {
      let obj = {
        normal: {
          color: res ? "#fff" : "",
          barBorderColor: res ? "#82BAFF" : null,
          barBorderWidth: 2,
          barBorderRadius: 0,
          label: {
            show: true, //开启显示
            position: "top", //在上方显示
            formatter: name ? `{c}%` : `{c}`,
            textStyle: {
              //数值样式
              color: "#000",
              fontSize: 11,
            },
          },
        },
      };
      return obj;
    },
    getchart(id,res){
     
        const myChart = this.echarts.init(document.getElementById(id));
       let option = {
          color:res.color,
          legend: {
            data: res.legend,
            formatter: ' {name}',
            itemHeight: 10,
            itemWidth: 18,
            right: '4%',
            textStyle: {
              color: '#000'
            }
          },
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'shadow'
            },
            formatter:res.tb?function(params){
             
              var tip = ""
              if(params!=null && params.length>0){
                if(res.tb&&Array.isArray(res.tb)){
                  if(params[0].value && typeof(params[0].value)!="undefined"){
                    tip += params[0].name + '<br/>'
                    tip += params[0].marker+params[0].seriesName+': '+ params[0].value+ '<br/>'+`<span style="display:inline-block;margin-right:4px;border-radius:10px;width:10px;height:10px;background-color:${res.color[1]};"></span>` +`${res.tbname}`+': '+res.tb[params[0].dataIndex]
                  }else{
                      tip += params[0].name + '<br/>'
                    tip += params[0].marker+params[0].seriesName+': '+ ""+ '<br/>'+`<span style="display:inline-block;margin-right:4px;border-radius:10px;width:10px;height:10px;background-color:${res.color[1]};"></span>` +`${res.tbname}`+': '+res.tb[params[0].dataIndex]
                  }
                   
                }else{
                  // if(params[0].value && typeof(params[0].value)!="undefined"){
                  //   tip += params[0].name + '<br/>'
                  //   tip += params[0].marker + params[0].seriesName+': '+ params[0].value
                  // }else{
                  //    tip += params[0].name + '<br/>'
                  //   tip += params[0].marker+params[0].seriesName+': '+ ""
                  // }
                  
                }
                
              }
              return tip
            }:null
            
          },
           grid: {
              left: '15%',
              right: '2%',
              bottom: '8%',
              top: '13%'
          },
          xAxis: {
            type: 'category',
            data: this.xdata,
             axisLine: {
                show: true,
                
              },
              axisTick: {
                show: false,
                interval: 0
              },
              axisLabel: {
                interval: 0,
              
              },
              axisPointer: {
                // type: 'shadow'
              }
          },
          yAxis:[
             {
            type: 'value',
            name:res.name,
            splitLine: { show: false },
            axisLine: {
              show: true
            },
            axisTick: {
              show: true
            }
          },
           {
            type: 'value',
            // max: 100,
            // interval: 10,
            scale: true,
            axisLabel: {
              // formatter: '{value} %'
              formatter: '{value}'
            },
            axisLine: {
              show: false
            },
            axisTick: {
              show: false,
              interval: 0
            },
            splitLine: {
              show: true,
             
            }
          }
          ],
          series: res.series
        };
        
        option && myChart.setOption(option, (window.onresize = myChart.resize));
    
      },
    getRadarChart(res) {
        let myChart = this.echarts.init(document.getElementById('radarChart'), null, {
          renderer: 'svg'
        });
        const indicatorArr = [];
        for (let i = 0; i < res.xAxis.length; i++) {
          const splitArr = res.xAxis[i].split('$');
          indicatorArr.push({
            name: splitArr[0],
            num: splitArr[1],
            unit: splitArr[2],
            max: res.max[i]
          });
        }
        let option = {
          legend: {
            data: ['当前累计完成情况'],
            right: '5%',
            icon: 'circle'
          },
          tooltip: {
            show: false,
            trigger: 'item',
            axisPointer: {
              type: 'shadow'
            }
          },
          radar: {
            center: ['50%', '50%'],
            radius: '52%',
            indicator: indicatorArr,
            nameGap: 50,
            name: {
              color: '#333333',
              lineHeight: 18,
              formatter: (name, item) => {

                return `${name} {a|${item.num}} ${item.unit}`;
              },
              rich: {
                a: {
                  color: 'red',
                  fontSize: 16
                }
              }
            }
          },
          series: [
            {
              name: '预算 vs 开销（Budget vs spending）',
              type: 'radar',
              data: [
                {
                  name: '当前累计完成情况',
                  value: res.yAxis,
                  label: {
                    normal: {
                      show: true,
                      formatter: function (params) {
                        return params.value;
                      }
                    }
                  },
                  areaStyle: {
                    normal: {
                      color: 'rgba(19, 189, 245, 0.2)' // 选择区域颜色
                    }
                  },
                  itemStyle: {
                    normal: {
                      color: 'rgba(19, 189, 245, 1)',
                      lineStyle: {
                        color: 'rgba(19, 189, 245, 1)'
                      }
                    }
                  }
                }
              ]
            }
          ]
        };
        myChart.setOption(option, (window.onresize = myChart.resize));

      },
       getindexRadar(){
        indexRadar({yearDate:this.value2}).then(res=>{
          if(res.data.code==200){
            let arr = [],arr1=[],arr2=[]
            res.data.data.map((item)=>{
              let pro = this.namelist.filter(v=>v.value===item.type)
              if(pro.length>0){
                arr.push(item.last)
                arr1.push(item.target)
                
                arr2.push(item.target)
                
              }
            })
            let radarChartData= {
              xAxis: [
                `技术（研究）\n开发费\n$${arr1[0] || 0}$亿元`,
                `高新产业\n投资建设\n$${arr1[1] || 0}$亿元`,
                `高新技术\n产业\n增加值\n$${arr1[2] || 0}$亿元`,
                `技术合同\n交易额\n$${arr1[3] || 0}$万元`,
                `高新技术\n企业$\n${arr1[4] || 0}$家`,
                `省级科技型\n中小企业\n$${arr1[5] || 0}$家`,
                `省级\n研发机构\n$${arr1[6] || 0}$家`,
                `绿谷精英.\n创新引领计划\n$${arr1[7] || 0}$家`
              ],
              yAxis: arr,
              max: arr2,
            }
            this.getRadarChart(radarChartData)
          }
        })
      },
    getjschart() {
      techDevcost({ yearDate: this.value2 }).then((res) => {
        if (res.data.code == 200) {
          let arr1 = [],
            arr2 = [];
          res.data.data.map((item) => {
            arr1.push(item.cost);
            if (item.yoy) {
              arr2.push(item.yoy);
            } else {
              arr2.push("");
            }
          });

          let obj = {
            series: [
              {
                name: "月累加(亿元)",
                data: arr1,
                type: "bar",
                barWidth: 16,
                itemStyle: this.getstyle(),
              },
              // {
              //   name: '占目标值比重 (%)',
              //   type: 'line',
              //   yAxisIndex:1,
              //   data: arr2,

              // },
            ],
            tb: arr2,
            tbname: "占目标值比重 (%)",
            legend: ["月累加(亿元)", "占目标值比重 (%)"],
            color:['#85CCF5','#58BD27'],
            name: "亿元",
          };
          this.getchart("jschart", obj);
        }
      });
    },
    getlgchart(){
        lgjyInnovateEnterprise({yearDate:this.value2}).then(res=>{
          if(res.data.code==200){
             let arr =[],arr1=[],arr2=[]
           res.data.data.map((item)=>{
              if(item.type=='排摸'){
                arr[item.monthDate-1] = item.number || '-'
              }else if(item.type=='申报'){
                arr1[item.monthDate-1] = item.number || '-'
              }else if(item.type=='认定'){
                arr2[item.monthDate-1] = item.number || '-'
              }
            })
            let obj ={
              series:[
                {
                  name:'排摸',
                  data: arr,
                  type: 'bar',
                  //  stack: 'Ad',
                  barWidth:16,
                  itemStyle: this.getstyle('','1')
                },
              {
                  name:'认定',
                  data: arr2,
                  //  stack: 'Ad',
                  barWidth:16,
                  type: 'bar',
                  itemStyle: this.getstyle()
                },
              ],
              legend:['排摸','认定'],
               color:['#82BAFF','#82BAFF'],
              name:'家'
            }
            this.getchart('lgchart',obj)
          }
        })
 
      },
      getjgchart(){
        presearchEnterprise({yearDate:this.value2}).then(res=>{
          if(res.data.code==200){
            let arr = [],arr1=[],arr2=[]
           res.data.data.map((item)=>{
              if(item.type=='排摸'){
                arr[item.monthDate-1] = item.number || '-'
              }else if(item.type=='申报'){
                arr1[item.monthDate-1] = item.number || '-'
              }else if(item.type=='认定'){
                arr2[item.monthDate-1] = item.number || '-'
              }
            })
            let obj ={
              series:[
                {
                  name:'排摸',
                  data: arr,
                  type: 'bar',
                  // stack: 'Ad',
                  barWidth:16,
                  itemStyle: this.getstyle('','1')
                },
                {
                  name:'认定',
                  data: arr2,
                  type: 'bar',
                  // stack: 'Ad',
                  barWidth:16,
                  itemStyle: this.getstyle()
                },
              ],
              legend:['排摸','认定'],
               color:['#82BAFF','#82BAFF'],
              name:'家'
            }
            this.getchart('jgchart',obj)
          }
        })
  
      },
      getcomchart(){
        hightechEnterprise({yearDate:this.value2}).then(res=>{
          if(res.data.code==200){
            let arr = [],arr1=[],arr2=[]
            res.data.data.map((item)=>{
              if(item.type=='排摸'){
                arr[item.monthDate-1] = item.number
              }else if(item.type=='申报'){
                arr1[item.monthDate-1] = item.number
              }else if(item.type=='认定'){
                arr2[item.monthDate-1] = item.number
              }
            })
            let obj ={
              series:[
                {
                  name:'排摸',
                  data: arr,
                  type: 'bar',
                  // stack: 'Ad',
                  barWidth:16,
                  itemStyle: this.getstyle('','1')
                },
                {
                  name:'认定',
                  data: arr2,
                  // stack: 'Ad',
                  type: 'bar',
                  barWidth:16,
                  itemStyle: this.getstyle()
                }
              ],
              legend:['排摸','认定'],
               color:['#82BAFF','#82BAFF'],
              name:'家'
            }
            this.getchart('comchart',obj)
          }
        })
  
      },
      getjhchart(){
        techDeal({yearDate:this.value2}).then(res=>{
          if(res.data.code==200){
            let arr = [],yarr=''
            res.data.data.map((item)=>{
              arr.push(item.cost)
              yarr = item.yearDate.toString()
            })
            this.value2 = yarr 
            let obj ={
              series:[
                {
                  name:'技术合同交易额',
                  data: arr,
                  type: 'bar',
                  barWidth:16,
                  itemStyle: this.getstyle()
                }
              ],
              legend:[],
              color:['#56A5FE'],
              name:'万元'
            }
            this.getchart('jhchart',obj)
          }
        })
  
      },
      getsjchart(){
        pmandstechEnterprise({yearDate:this.value2}).then(res=>{
          if(res.data.code==200){
            let arr = [],arr1=[],arr2=[]
            res.data.data.map(item=>{

              if(item.type=='排摸'){
                arr.push(item.number || '-')
              }else if(item.type=='申报'){
                arr1.push(item.number || '-')
              }else if(item.type=='认定'){
                arr2.push(item.number || '-')
              }
            })
            let obj ={
              series:[
                {
                  name:'排摸',
                  data:arr,
                  type: 'bar',
                  // stack: 'Ad',
                  barWidth:16,
                  itemStyle: this.getstyle('','1')
                },
                {
                  name:'认定',
                  data:arr2,
                  type: 'bar',
                  // stack: 'Ad',
                  barWidth:16,
                  itemStyle: this.getstyle()
                },
              ],
              legend:['排摸','认定'],
              color:['#82BAFF','#82BAFF'],
              name:'家'
            }
            this.getchart('sjchart',obj)
          }
        })
  
      },
     
      getgjchart(){
        highIncrease({yearDate:this.value2}).then(res=>{
          if(res.data.code==200){
            let arr1 =[],arr2=[]
            res.data.data.map((item)=>{
              arr1.push(item.cost)
              if(item.yoy){
                arr2.push(item.yoy)
              }else[
                arr2.push("")
              ]
              
            })
      
            let obj ={
              series:[
                {
                  name: '月累加(亿元)',
                  data: arr1,
                  type: 'bar',
                  barWidth:16,
                  itemStyle: this.getstyle()
                },
               
              ],
              tb:arr2,
              tbname:"占目标值比重 (%)",
              legend:['月累加1','占目标值比重 (%)'],
              color:['#819EF1','#006AE0'],
              name:'亿元'
            }
            this.getchart('gjchart',obj)
          }
        })
    
      },
      getgxchart(){
       highInvest({yearDate:this.value2}).then(res=>{
         if(res.data.code==200){
            let arr1 =[],arr2=[]
            res.data.data.map((item)=>{
              arr1.push(item.cost)
              if(item.yoy){
                arr2.push(item.yoy)
              }else{
                arr2.push('')
              }
             
            })
            let obj ={
              series:[
                {
                  name: '占目标值比重 (%)',
                  data: arr2,
                  type: 'line',
                  // barWidth:16,
                  itemStyle: this.getstyle()
                },
                // {
                //   name: '月累加',
                //   type: 'line',
                //   yAxisIndex: 1,
                //   data: arr1,
                  
                // },
              ],
              tb:arr1,
              tbname:'月累加(亿元)',
              legend:[],
              color:['#FFBC7E','#FF9018'],
              name:'%'
            }
            this.getchart('gxchart',obj)
         }
       })
   
      },
      getjschart(){
        techDevcost({yearDate:this.value2}).then(res=>{
          if(res.data.code==200){
            let arr1 =[],arr2=[]
            res.data.data.map((item)=>{
              
              arr1.push(item.cost)
              if(item.yoy){
                arr2.push(item.yoy)
              }else{
                arr2.push('')
              }
              
            })
           
            let obj ={
              series:[
                {
                  name: '月累加(亿元)',
                  data: arr1,
                  type: 'bar',
                  barWidth:16,
                  itemStyle: this.getstyle()
                },
                // {
                //   name: '同比 (%)',
                //   type: 'line',
                //   yAxisIndex:1,
                //   data: arr2,
                  
                // },
              ],
              tb:arr2,
              tbname:'占目标值比重 (%)',
              legend:['月累加','占目标值比重 (%)'],
              color:['#85CCF5','#58BD27'],
              name:'亿元'
            }
            this.getchart('jschart',obj)
          }
        })
  
      },

 
  },
};
</script>

<style lang="scss" scoped>
.center_container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #fff;
  // margin: 20px 0;
  // box-shadow: 0px 3px 0.75rem 1px rgba(12, 55, 146, 0.15);
  // border-radius: 0.625rem;

  // FIXME
  // height: 1620px !important;
  .wrraper {
    background: #ffffff;
    box-shadow: 0px 2px 0.375rem 1px rgba(12, 55, 146, 0.15);
    margin-bottom: 24px;
    .zbqingk{
          position: absolute;
          padding-left:5px ;
          z-index: 999;
          right: 10%;
          top: 9.8%;
          display: flex;
          justify-content: center;
          align-items: center;
          // color: #fff;
          font-size: 12px;
          span{
            display: inline-block;
            margin-right: 10px;
            width: 14px;
            height: 14px;
            border-radius: 50%;
            background:#f28238;
          }
        }
  }
  .top_title {
    font-size: 1.125rem;
    padding: 24px 0;
    font-weight: bold;
    // border-bottom: 4px solid #13bdf5;
  }
  .chart_box {
    padding: 16px 14px 20px;
    width: 100%;
    height: 300px;
    display: flex;
    flex-direction: column;
    .chart_title {
      text-align: left;
      border-left: 4px solid #13bdf5;
      font-weight: bold;
      font-size: 0.875rem;
      padding-left: 0.5rem;
      margin-bottom: 0.375rem;
      color: rgba(51, 51, 51, 1);
    }
    .chart_content {
      flex: 1;height: 100%;
    }
  }
  .tips{font-size: 12px;color: #999; margin-bottom: 10px;}
}

</style>
